import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { isValid as isDateValid, parseISO } from 'date-fns';
import cn from 'classnames';

import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from '../../Auth.module.scss';

const dateMask = '99/99/9999';
const maskPlaceholder = 'ДД/ММ/ГГГГ';

export default function AuthBirthdayStep({ onSubmit, changePhoneLink, isLoading, welcomeText }) {
  const [isStepValid, setIsStepValid] = useState(false);
  const [date, setDate] = useState('');
  const dateInputRef = useRef();

  const checkStepValid = () => {
    const [day, month, year] = date.split('/');
    const isoDate = parseISO(`${year}-${month}-${day}`);

    setIsStepValid(isDateValid(isoDate));
  };

  const handleSubmitClick = () => {
    onSubmit(date);
  };

  useEffect(() => {
    dateInputRef.current.focus();
  }, []);

  useEffect(() => {
    checkStepValid();
  }, [date]);

  return (
    <>
      <h1 className={styles.Auth__stepHeader}>
        { welcomeText.age_restriction ? `Дата рождения (${welcomeText.age_restriction}+)` : 'Дата рождения' }
      </h1>
      <div className={styles.Auth__inputWrap}>
        <InputMask
          mask={dateMask}
          maskPlaceholder={maskPlaceholder}
          alwaysShowMask
          onChange={(e) => setDate(e.target.value)}
          value={date}
        >
          <input
            className="input input_large input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            ref={dateInputRef}
          />
        </InputMask>
        {
          isStepValid
          && (
            <button
              className={cn(styles.Auth__inputAddonBtn, 'button button_small', styles.Auth__bg)}
              disabled={!isStepValid || isLoading}
              onClick={handleSubmitClick}
              type="submit"
            >
              <IconSvg icon="arrow-right" />
            </button>
          )
        }
      </div>

      <div className={styles.Auth__inputInfo}>
        {changePhoneLink}
      </div>

      <div className={styles.Auth__buttonWrap}>
        <button
          className={cn('button button_large button_fullwidth', styles.Auth__bg)}
          disabled={!isStepValid || isLoading}
          onClick={handleSubmitClick}
          type="submit"
        >
          Далее
        </button>
      </div>
    </>
  );
}

AuthBirthdayStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  changePhoneLink: PropTypes.element,
  isLoading: PropTypes.bool,
  welcomeText: PropTypes.shape({
    text_1: PropTypes.string,
    text_2: PropTypes.string,
    text_3: PropTypes.string,
    age_restriction: PropTypes.number,
  }).isRequired,
};

AuthBirthdayStep.defaultProps = {
  changePhoneLink: null,
  isLoading: false,
};
