import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import ContentNotice from '../../../components/ContentNotice';
import TagsFilter from '../../../components/TagsFilter';
import TagsFilterList from '../../../components/TagsFilterList';
import TagsFilterButton from '../../../components/TagsFilterButton';
import ProductCardsList from './components/CardsList';
import PlayerChest from '../components/PlayerChest';
import ProductStore from '../../../stores/products';
import ProgramMemberButton from '../../../components/ProgramMemberButton';

function ProductsListScreen({ player, products, initialFilterTagId, settings }) {
  const [currentFilterTagId, setCurrentFilterTagId] = useState(initialFilterTagId);
  const {
    collectedTags,
  } = ProductStore;

  const handleTagClick = (id) => {
    setCurrentFilterTagId(id);
  };

  const tagItems = collectedTags.map((tag) => (
    <li key={`tagItem_${tag.id}`}>
      <TagsFilterButton
        label={tag.title}
        onClick={() => handleTagClick(tag.id)}
        active={tag.id === currentFilterTagId}
      />
    </li>
  ));

  return (
    <>
      {!player.program_member && <ProgramMemberButton settings={settings} /> }
      <PlayerChest player={player} />

      {tagItems.length > 0 && (
        <TagsFilter count={tagItems.length}>
          <TagsFilterList>
            <li>
              <TagsFilterButton
                label="Все"
                onClick={() => handleTagClick(null)}
                active={currentFilterTagId === null}
              />
            </li>
            {tagItems}
          </TagsFilterList>
        </TagsFilter>
      )}

      <ContentNotice
        uid="productListCardOpen"
        outline
        bottomSpace
      >
        <div
          // className={styles.WinnerAward__prize}
          dangerouslySetInnerHTML={{ __html: settings?.product_notice || 'Нажмите на карточку, чтобы прочитать подробнее о призе и купить его' }}
        />
      </ContentNotice>

      <ProductCardsList
        filterTagId={currentFilterTagId}
        items={products}
        player={player}
      />
    </>
  );
}

ProductsListScreen.propTypes = {
  player: PropTypes.shape({
    program_member: PropTypes.bool,
  }).isRequired,
  products: PropTypes.array,
  initialFilterTagId: PropTypes.number,
  settings: PropTypes.shape({
    bracelet_text: PropTypes.string,
    product_notice: PropTypes.string,
    bracelet_url: PropTypes.string,
  }).isRequired,
};

export default observer(ProductsListScreen);
