/* eslint-disable react/prop-types */
// TODO: refactor; move logic to helpers and submit in api;
import React, { useState } from 'react';
import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import TopPageNotice from '../../../../../components/TopPageNotice';
import AttentionMessage from '../../../../../components/AttentionMessage';
import Cost from '../../../../../components/Cost';
import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import ProductOrderCartItem from '../CartItem';
import { variantToCartItem, calcTotal } from '../../helpers/cart';

import styles from './OrderForm.module.scss';
import BottomSlideUp from '../../../../../components/BottomSlideUp/BottomSlideUp';
import TbankReAuth from '../../../../../components/TbankReAuth/TbankReAuth';

const productOrderSubmitPath = '/shop/product_orders.json';

export default function ProductOrderForm({ product, player, onShowOrder }) {
  const [notice, setNotice] = useState('');
  const [isAgreeChecked, setIsAgreeChecked] = useState(true);

  let productVariants = product.variants.filter((variant) => variant.total_buyed_by_current_player < variant.max_per_player);
  productVariants = productVariants.length === 0 ? [{}] : productVariants;

  const [cart, setCart] = useState(() => {
    const initialCart = [variantToCartItem(product, (productVariants || [{}])[0])];
    return initialCart;
  });

  const [totalPlayerPoints, totalTeamPoints, totalItemAmounts] = calcTotal(cart, product);

  const canRemoveLastVariant = cart.length > 1;
  const canAddNewVariant = cart.length < productVariants.length;
  // eslint-disable-next-line max-len
  const isEnoughPoints = (player.balance_player_points >= totalPlayerPoints) && (player.balance_team_points >= totalTeamPoints);
  const canSpentTeamPoints = product.cost_team_points > 0 ? player['in_active_team?'] : true;

  const isOnlyForProgramMember = product.only_for_program_members;
  const isPremiumSubscription = product.is_premium_subscription;
  const isProgramMember = player.program_member;
  const hasPremiumSubscription = player.premium_subscription;
  const canOrder = isEnoughPoints && canSpentTeamPoints && (totalItemAmounts > 0);
  const isBooster = cart[0].booster;
  const isCodeExpired = cart[0].available_codes && !cart[0].pending_codes && !isBooster;

  const [showSlideUp, setShowSlideUp] = useState(false);

  function variantsNotInCart() {
    const cartVariantsIds = (cart || []).map((item) => item.variant_id);

    return productVariants.filter((v) => {
      if (!cartVariantsIds.includes(v.id)) {
        return true;
      }
      return null;
    });
  }

  function nextVariantToAdd() {
    return (variantsNotInCart() || [])[0];
  }

  const submitOrder = async () => {
    try {
      const data = {
        product_order: { product_order_items: cart },
      };
      const response = await fetch(productOrderSubmitPath, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (response.ok) {
        if (result.status === 'ok') {
          // visitOrRedirectTo(result.order_url);
          onShowOrder(result);
        } else {
          setNotice(result.notice);
        }
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleOrderSubmit = () => {
    if (canOrder) {
      submitOrder();
    }
  };

  const addNextVariantToCart = () => {
    const newItem = variantToCartItem(product, nextVariantToAdd(), {
      id: cart.length + 1,
    });

    setCart((prevCart) => [...prevCart, newItem]);
  };

  const removeLastVariantFromCart = () => {
    if (cart.length < 2) return;

    const newCart = [...cart];

    newCart.pop();
    setCart(newCart);
  };

  const handleCartItemChange = (itemChanged) => {
    const newCart = [...cart];
    const index = newCart.findIndex((i) => i.id === itemChanged.id);

    newCart[index] = itemChanged;
    setCart(newCart);
  };

  const cartItems = cart.map((item) => (
    <ProductOrderCartItem
      key={item.id}
      item={item}
      onItemChange={(itemChanged) => handleCartItemChange(itemChanged)}
      variantsNotInCart={variantsNotInCart()}
      isEnoughPoints={isEnoughPoints}
      player={player}
      product={product}
    />
  ));

  const addNewVariantButton = (
    <button
      className={styles.OrderForm__newVariantBtn}
      onClick={addNextVariantToCart}
      type="button"
    >
      <span className={styles.OrderForm__newVariantIcon}>
        <IconSvg icon="plus" />
      </span>
      <span className={styles.OrderForm__newVariantLabel}>
        {`Добавить ${product.variants_name}`}
      </span>
    </button>
  );

  const removeLastVarianButton = (
    <button
      onClick={removeLastVariantFromCart}
      className={styles.OrderForm__removeVariantBtn}
      type="button"
    >
      <span className={styles.OrderForm__removeVariantLabel}>
        Удалить
      </span>
    </button>
  );

  const handleAreeChange = (e) => {
    const { checked } = e.target;

    setIsAgreeChecked(checked);
  };

  const handleTbankClick = () => {
    setShowSlideUp(true);
  };

  return (
    <>
      <Card noShadow className={styles.OrderForm}>
        {notice && (
          <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
        )}

        <CardHeader>
          <h2>Форма покупки</h2>
        </CardHeader>

        <div className={styles.OrderForm__variants}>
          {cartItems}
        </div>

        {((canAddNewVariant || canRemoveLastVariant) && !isCodeExpired) && (
          <div className={styles.OrderForm__variantActions}>
            {canAddNewVariant && addNewVariantButton}
            {canRemoveLastVariant && removeLastVarianButton}
          </div>
        )}

      <div className={styles.OrderForm__totalWrap}>
        К оплате
        <div className={styles.OrderForm__totalCost}>
          <Cost icon="player-points" amount={totalPlayerPoints} showZero />
          {/* <Cost icon="team-points" amount={totalTeamPoints} showZero /> */}
        </div>
      </div>

        <button
          className="button button_fullwidth button_large"
          onClick={(!canOrder || (isOnlyForProgramMember && !isProgramMember) || isCodeExpired || (isPremiumSubscription && !hasPremiumSubscription)) || !isAgreeChecked ? null : handleOrderSubmit}
          disabled={!canOrder || (isOnlyForProgramMember && !isProgramMember) || isCodeExpired || (isPremiumSubscription && !hasPremiumSubscription) || !isAgreeChecked}
          type="submit"
        >
          Купить
        </button>

        <div className={styles.OrderForm__agreeWrap}>
          <input
            id={`product${product.id}`}
            type="checkbox"
            className="checkbox"
            onChange={handleAreeChange}
            checked={isAgreeChecked}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={`product${product.id}`}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: product.policy }}
            />
          </label>
        </div>

        <div className={styles.OrderForm__error}>
          {
            !isEnoughPoints && (
              <AttentionMessage message="Недостаточно средств для покупки" />
            )
          }

          {
            !canSpentTeamPoints && (
              <AttentionMessage message="Чтобы купить приз, ты должен быть в команде минимум из 2 человек" />
            )
          }

          {
            (isOnlyForProgramMember && !isProgramMember) && (
              <AttentionMessage>
                <a
                  rel="noreferrer"
                  href="https://spotway.ru/rosafest#anchor-page-event__booking"
                  target="_blank"
                >
                  Доступно только по аккредитации мероприятия
                </a>
              </AttentionMessage>
            )
          }

          {
            (isPremiumSubscription && !hasPremiumSubscription) && (
              <AttentionMessage>
                <a
                  rel="noreferrer"
                  href='javascript:void(0)'
                  onClick={handleTbankClick}
                >
                  Только для клиентов Т-Банка с подпиской premium и private.
                </a>
              </AttentionMessage>
            )
          }
        </div>
      </Card>

      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <TbankReAuth onClose={() => setShowSlideUp(false)} />
      </BottomSlideUp>
    </>
  );
}
