import React from 'react';
import PropTypes from 'prop-types';

import FromEditor from '../../../../../components/FromEditor';

import styles from './AnswerTypeHint.module.scss';

export default function AnswerTypeHint({ children }) {
  return (
    <FromEditor className={styles.AnswerTypeHint__item}>
      {children}
    </FromEditor>
  );
}

AnswerTypeHint.propTypes = {
  children: PropTypes.string,
};

AnswerTypeHint.defaultProps = {
  children: '',
};
