/* eslint-disable import/no-unresolved */
import React from 'react';
import cn from 'classnames';

import IconSvg from 'common/javascripts/components/IconSvg';
import Avatar from '../../../Avatar';
import TopbarCloseMenu from '../CloseMenu';

export default function TopbarTeamSection({
  isMenuOpened, team, teamAPI, onClick, styles
}) {
  if (team) {
    return (
      <button className={styles.Topbar__team} onClick={onClick} type="button">
        <div className={cn(styles.Topbar__avatar, styles.Topbar__avatar_team)}>
          {
            isMenuOpened && <TopbarCloseMenu />
          }
          <Avatar type="team" avatar={team.avatar_url} />
        </div>
        {/* <span className={styles.Topbar__profileText}>Моя&nbsp;</span>
        <span className={cn(styles.Topbar__profileText, styles.Topbar__profileText_highlight)}>
          команда
        </span> */}
        <span className={styles.Topbar__profileText}>Команда</span>
      </button>
    );
  }

  return (
    <a className={styles.Topbar__team} href={teamAPI.newTeamPath}>
      <div className={cn(styles.Topbar__avatar, styles.Topbar__avatar_team)}>
        <IconSvg icon="play-circle" className={styles.Topbar__iconCircle} />
      </div>
      <span className={styles.Topbar__profileText}>Играть&nbsp;</span>
      <span className={cn(styles.Topbar__profileText, styles.Topbar__profileText_highlight)}>
        в команде
      </span>
    </a>
  );
}
