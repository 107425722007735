import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BuyHint from '../BuyHint';
import SubmitButton from '../AnswerSubmit';
import TopPageNotice from '../../../../../components/TopPageNotice';
import CardHeader from '../../../../../components/CardHeader';

import styles from './Checkboxes.module.scss';
import AnswerTypeHint from '../AnswerTypeHint/AnswerTypeHint';

const HEADERS = (token) => (
  {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
);

export default function AnswerCheckboxes({ taskStep, token, onTaskAnswer }) {
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const checkedAnswersList = [...answers];

    if (checked) {
      checkedAnswersList.push(value);
    } else {
      const index = checkedAnswersList.indexOf(value);
      if (index > -1) {
        checkedAnswersList.splice(index, 1);
      }
    }
    setAnswers(checkedAnswersList);
  };

  const handleSubmitClick = async () => {
    setNotice('');
    setIsLoading(true);

    const data = {
      task_step_id: taskStep.id,
      task_answer: { answer: answers },
    };

    try {
      const response = await fetch('/task_answers.json', {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isDisabled = isLoading || taskStep.attempts_left <= 0 || answers.length === 0;

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      <CardHeader bottomSpace="s">
        {!taskStep?.answer_type_hint && (
          <h4>
            Выбери правильные ответы.
            <br />
            Правильный ответ может быть один или несколько.
          </h4>
        )}
        {taskStep?.answer_type_hint && <AnswerTypeHint>{taskStep?.answer_type_hint}</AnswerTypeHint>}
      </CardHeader>

      <div>
        {(taskStep.variants || []).map((variant) => (
          <div key={variant.id} className={styles.CheckboxWrap}>
            <input
              id={`answerVariant${variant.id}`}
              type="checkbox"
              className="checkbox"
              value={variant.id}
              onChange={handleChange}
            />
            <label htmlFor={`answerVariant${variant.id}`}>
              {variant.answer}
            </label>
          </div>
        ))}
      </div>

      <BuyHint text={taskStep.human_attempts_left} />
      <SubmitButton disabled={isDisabled} onClick={handleSubmitClick} />
    </>
  );
}

AnswerCheckboxes.propTypes = {
  taskStep: PropTypes.object,
  token: PropTypes.string,
  onTaskAnswer: PropTypes.func.isRequired,
};
