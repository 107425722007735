import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TopPageNotice from '../../../../../components/TopPageNotice';

import styles from './AnswerTbankSubscription.module.scss';
import TbankReAuth from '../../../../../components/TbankReAuth';
import BottomSlideUp from '../../../../../components/BottomSlideUp';

export default function AnswerTbankSubscription({ taskStep, token, onTaskAnswer }) {
  const [notice, setNotice] = useState();
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handleClick = () => {
    setShowSlideUp(true);
  };

  return (
    <form className={styles.AnswerText}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <div className={styles.AnswerTbankSubscription__description}>
        Задание автоматически засчитается при наличии подписка Premium или Private от Т-Банка.&nbsp;
        <a
          className={styles.AnswerTbankSubscription__link}
          onClick={handleClick}
        >
          Подробнее
        </a>
      </div>

      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <TbankReAuth />
      </BottomSlideUp>
    </form>
  );
}

AnswerTbankSubscription.propTypes = {
  taskStep: PropTypes.shape({
    answer_type_hint: PropTypes.string,
  }).isRequired,
  token: PropTypes.string,
  onTaskAnswer: PropTypes.func,
};
