import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import QRCode from '../../../../../components/QRCode';
import EventRegistration from '../Registration';
import { scrollToElement } from '../../../../../helpers/scroll';

import styles from './QRCodeShow.module.scss';

function QRCodeShow({
  event, player, day, onRegistration,
}) {
  const code = `${event.id}#${player.id}#${day}`;
  const cardRef = useRef();

  const scrollToQR = () => {
    scrollToElement({
      getTarget: () => cardRef.current,
      offsetY: -40,
    });
  };

  useEffect(() => {
    scrollToQR();
  }, []);

  return (
    <div ref={cardRef}>
      <Card noShadow className={styles.QRCodeShow}>
        <CardHeader bottomSpace="m">
          <h2><center>Покажите QR-код при входе на мероприятие</center></h2>
        </CardHeader>
        <div className={styles.QRCodeShow__qr}>
          <QRCode value={code} size={180} />
        </div>

        <EventRegistration
          event={event}
          onAction={onRegistration}
          onlyButton
        />
      </Card>
    </div>
  );
}

QRCodeShow.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  day: PropTypes.string.isRequired,
  onRegistration: PropTypes.func.isRequired,
};

export default React.memo(QRCodeShow);
