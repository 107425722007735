import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
// import AuthPageHeader from '../Header';

// import styles from './../../Auth.module.scss';
import styles from './Welcome.module.scss';

export default function AuthWelcome({ onNext, welcomeText }) {
  return (
    <>
      <div className={styles.Welcome__row}>
        <div className={styles.Welcome__counter}>1</div>
        <div
          className={styles.Welcome__item}
          dangerouslySetInnerHTML={{ __html: welcomeText.text_1 }}
        />
      </div>
      <div className={styles.Welcome__row}>
        <div className={styles.Welcome__counter}>2</div>
        <div
          className={styles.Welcome__item}
          dangerouslySetInnerHTML={{ __html: welcomeText.text_2 }}
        />
      </div>
      <div className={styles.Welcome__row}>
        <div className={styles.Welcome__counter}>3</div>
        <div
          className={styles.Welcome__item}
          dangerouslySetInnerHTML={{ __html: welcomeText.text_3 }}
        />
      </div>

      <div className={styles.Welcome__row}>
        <div className={styles.Welcome__bankLabel}>АО «ТБанк»</div>
      </div>

      <div className={styles.Welcome__buttonWrap}>
        <button
          type="button"
          className={cn('button button_large button_fullwidth', styles.Welcome__bg)}
          onClick={onNext}
        >
          Играть
        </button>
      </div>
    </>
  );
}

AuthWelcome.propTypes = {
  onNext: PropTypes.func.isRequired,
  welcomeText: PropTypes.shape({
    text_1: PropTypes.string,
    text_2: PropTypes.string,
    text_3: PropTypes.string,
  }).isRequired,
};
