/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './PlayerSubscriptionSection.module.scss';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg/IconSvg';

const INDIVIDUAL_SUBSCRIPTION = {
  'DEFAULT': 'tbank_shield_yellow',
  'PRO': 'tbank_shield_yellow',
  'TEAM_PRO': 'tbank_shield_yellow',
  'TEAM_SELECT': 'tbank_shield_yellow',
  'PREMIUM': 'tbank_premium_badge',
  'TEAM_PREMIUM': 'tbank_premium_badge',
  'TEAM_PRIVATE': 'tbank_private',
  'PRIVATE': 'tbank_private',
};

export default function PlayerSubscriptionSection({ player }) {
  return (
    <div className={styles.PlayerSubscriptionSection}>
      <IconSvg icon={INDIVIDUAL_SUBSCRIPTION[player.individual_subscription_tbank]} />
    </div>
  );
}

PlayerSubscriptionSection.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.number.isRequired,
    individual_subscription_tbank: PropTypes.string.isRequired,
  }).isRequired,
};

PlayerSubscriptionSection.defaultProps = {
};
