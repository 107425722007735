/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../common/javascripts/components/IconSvg';
import BottomSlideUp from '../BottomSlideUp';
import QRCode from '../QRCode';

import styles from './QRShowBtn.module.scss';

export default function QRShowBtn({ label, value, labelVisible }) {
  const [showQR, setShowQR] = useState(false);

  const handleClick = () => setShowQR(!showQR);

  return (
    <>
      <button className={styles.Button} type="button" onClick={handleClick}>
        <IconSvg icon="qr" className={styles.Button__icon} />
        {labelVisible && label}
      </button>

      <BottomSlideUp show={showQR} onClose={() => setShowQR(false)}>
        <div className={styles.SlideUp}>
          <IconSvg icon="slide-up-qr" className={styles.SlideUp__icon} />
          <h2>{label}</h2>
          {
            value && (
              <div className={styles.SlideUp__qr}>
                <QRCode value={value} size={180} />
              </div>
            )
          }
          <button
            type="button"
            className="button button_large button_fullwidth"
            onClick={() => setShowQR(false)}
          >
            OK
          </button>
        </div>
      </BottomSlideUp>
    </>
  );
}

QRShowBtn.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  labelVisible: PropTypes.bool,
};

QRShowBtn.defaultProps = {
  label: 'QR-код',
  value: null,
  labelVisible: true,
};
