import React from 'react';

import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import OrderCodeItem from '../CodeItem';
import ProductOrderVariantItem from '../VariantItem';
import ProductOrderAttachmentItem from '../AttachmentItem';

import styles from './OrderInfo.module.scss';

export default function ProductOrderInfo({ order }) {
  const hasTaskCodes = order.task_codes && order.task_codes.length > 0;
  const hasVariantCodes = order.product_variant_codes && order.product_variant_codes.length > 0;

  const attachmentItems = (order.attachments || []).map((item) => (
    <ProductOrderAttachmentItem item={item} key={item.id} />
  ));

  const variantItems = (order.items || []).map((item) => (
    <ProductOrderVariantItem item={item} key={item.id} />
  ));

  const taskCodeItems = (order.task_codes || []).map((item) => (
    <OrderCodeItem item={item} key={item.id} />
  ));

  const variantCodeItems = (order.product_variant_codes || []).map((item) => (
    <OrderCodeItem alwaysCanUse item={item} key={item.id} />
  ));

  return (
    <div>
      <Card noShadow className={styles.OrderInfo}>
        <div className={styles.OrderInfo__titleWrap}>
          <CardHeader>
            <h2>
              Спасибо
              <br />
              за покупку
            </h2>
          </CardHeader>
          <div className={styles.OrderInfo__id}>
            {`#${order.id}`}
          </div>
        </div>

        {/* <div className={styles.OrderInfo__description}> */}
        {/* Смотри ниже, как и где ты можешь получить свой приз */}
        {/* </div> */}

        <div className={styles.OrderInfo__contentWrap}>
          {variantItems}
        </div>

        <div className={styles.OrderInfo__contentWrap}>
          {/* <div className={styles.OrderInfo__codesLabel}>твой код</div> */}
          {hasTaskCodes && taskCodeItems}
          {hasVariantCodes && variantCodeItems}
        </div>

        <div className={styles.OrderInfo__contentWrap}>
          {attachmentItems}
        </div>

      </Card>
    </div>
  );
}
