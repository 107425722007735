import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './VkButton.module.scss';
import IconSvg from 'common/javascripts/components/IconSvg';

const VkButton = ({ className, onClick }) => (
  <div
    className={
      cn(
        styles.VkButton,
        { [className]: className },
      )
    }
  >
    <button
      type="button"
      className={cn('button button_large button_fullwidth', styles.VkButton, styles.VkButton__button, styles.VkButton__button_reset)}
      onClick={() => onClick()}
      themes={['Button', 'Fullwidth']}
    >
      <IconSvg icon="vk_id1" className={styles.VkButton__logo} />
      <div className={styles.VkButton__info}>Войти c VK ID</div>
      <IconSvg icon="SW" className={styles.VkButton__avatar} />
    </button>
  </div>
);

VkButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

VkButton.defaultProps = {
  className: '',
};

export default VkButton;
