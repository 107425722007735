import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../../../../../components/CardHeader';
import QRCode from '../../../../../components/QRCode';
import AnswerTypeHint from '../AnswerTypeHint/AnswerTypeHint';

function QRCodeShow({ taskStep, player }) {
  const code = `${taskStep.id}#${player.id}`;

  return (
    <>
      <CardHeader bottomSpace="s">
        {!taskStep?.answer_type_hint && (
          <h4>Покажите QR-код модератору</h4>
        )}
        {taskStep?.answer_type_hint && <AnswerTypeHint>{taskStep?.answer_type_hint}</AnswerTypeHint>}
      </CardHeader>
      <center>
        <QRCode value={code} size={148} />
      </center>
    </>
  );
}

QRCodeShow.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default QRCodeShow;
