import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import { headersWithToken } from '../../../../common/javascripts/fetchOptions';
// import currentPlayerStore from '../../stores/currentPlayerStore';

import styles from './PlayerRate.module.scss';

export default function PlayerRate({
  paths, rate, header, className,
}) {
  const [like, setLike] = useState(rate);

  const submit = async (path) => {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: headersWithToken(),
      });
      const result = await response.json();
      if (response.ok) {
        setLike(result.rate);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleClickLike = () => {
    const path = like ? paths.cancel_player_rate : paths.player_rate_up;

    submit(path);
  };

  const handleClickNotLike = () => {
    const path = (like === false) ? paths.cancel_player_rate : paths.player_rate_down;

    submit(path);
  };

  return (
    <div className={cn(className, styles.PlayerRate, styles[`PlayerRate_${like}`])}>
      <div className={styles.PlayerRate__header}>
        {header}
      </div>
      <div className={styles.PlayerRate__buttons}>
        <button
          type="button"
          onClick={handleClickNotLike}
          className={cn(styles.PlayerRate__button, styles.PlayerRate__button_unlike)}
        >
          <IconSvg icon="unlike" />
        </button>
        <button
          type="button"
          onClick={handleClickLike}
          className={cn(styles.PlayerRate__button, styles.PlayerRate__button_like)}
        >
          <IconSvg icon="like" />
        </button>
      </div>

      {
        like !== null && (
          <div className={styles.PlayerRate__thanksMsg}>
            Спасибо за оценку
          </div>
        )
      }

    </div>
  );
}

PlayerRate.propTypes = {
  paths: PropTypes.shape({
    player_rate_up: PropTypes.string,
    player_rate_down: PropTypes.string,
    cancel_player_rate: PropTypes.string,
  }).isRequired,
  rate: PropTypes.bool,
  header: PropTypes.string,
  className: PropTypes.string,
};

PlayerRate.defaultProps = {
  rate: null,
  header: 'Нравится?',
  className: null,

};
