import React, { useState, useEffect } from 'react';

import * as styles from './TopButton.module.scss';

import IconSvg from 'common/javascripts/components/IconSvg';

export default function TopButton() {
  const [visible, setVisible] = useState(false);

  const handleScrollToTop = () => {
    const scrolled = document.getElementById('js-scroll-container');

    scrolled.scrollTop = 0;
  };

  useEffect(() => {
    const scrolled = document.getElementById('js-scroll-container');

    const toggleVisible = () => {
      if (scrolled.scrollTop > 300) {
        setVisible(true);
      } else if (scrolled.scrollTop <= 300) {
        setVisible(false);
      }
    };

    scrolled.addEventListener('scroll', toggleVisible);

    return () => scrolled.removeEventListener('scroll', toggleVisible);
  }, []);

  return (
    <div
      role='button'
      tabIndex='0'
      className={styles.TopButton}
      style={{ display: visible ? 'inline-flex' : 'none' }}
      onClick={handleScrollToTop}
    >
      <IconSvg
        icon="top_scroll"
        className={styles.TopButton__icon}
      />
    </div>
  );
}
