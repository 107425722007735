/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Card from '../Card';
import CardHeader from '../CardHeader';
import Cost from '../Cost';
import TinkoffPromoSlideUp from './components/SlideUp';

import currentPlayerStore from '../../stores/currentPlayerStore';

import AACardImage from '../../../images/card-aa.png';
import styles from './TinkoffPromoCard.module.scss';
import clickCounter from '../../helpers/counter';

export default function TinkoffPromoCard({ isBalance, setting }) {
  const [showSlideUp, setShowSlideUp] = useState(false);

  const { player } = currentPlayerStore;

  const handleCardClick = () => {
    setShowSlideUp(true);
    clickCounter(`/backoffice/settings/${setting.id}`, setting);
  };

  const handleSlideUpClose = () => {
    setShowSlideUp(false);
  };

  return (
    <>
      <Card className={cn(styles.PromoCard, { [styles.PromoCard_balanceTheme]: isBalance })}>
        <div
          onClick={handleCardClick}
          className={styles.PromoCard__image}
          style={{ backgroundImage: `url(${(isBalance ? setting.img_url_virtual_card : setting.img_url_card) || AACardImage})` }}
        />
        <div className={styles.PromoCard__content} onClick={handleCardClick}>
          {
            !isBalance
            && (
              <>
                <CardHeader><h4><center>{setting.title_card}</center></h4></CardHeader>
                <div className={styles.PromoCard__text}>
                  {setting.text_card}
                </div>
                <button className="button button_large button_fullwidth" type="button">
                  Подробнее
                </button>
              </>
            )
          }
          {
            isBalance
            && (
              <>
                <CardHeader><h4><center>{setting.title_virtual_card}</center></h4></CardHeader>
                <div className={styles.PromoCard__text}>
                  {setting.text_virtual_card}
                </div>
                <div className={styles.PromoCard__miles}>
                  <Cost icon="bonus-points" amount={player.balance_bonus_points} showZero />
                </div>
              </>
            )
          }
        </div>
      </Card>

      <TinkoffPromoSlideUp
        show={showSlideUp}
        onClose={handleSlideUpClose}
        isBalance={isBalance}
        setting={setting}
      />
    </>
  );
}

TinkoffPromoCard.propTypes = {
  isBalance: PropTypes.bool,
  setting: PropTypes.shape({
    id: PropTypes.number.isRequired,
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
    img_url_virtual_card: PropTypes.string.isRequired,
    title_virtual_card: PropTypes.string.isRequired,
    text_virtual_card: PropTypes.string.isRequired,
    description_virtual_card: PropTypes.string.isRequired,
  }).isRequired,
};

TinkoffPromoCard.defaultProps = {
  isBalance: false,
};
