import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './TbankButton.module.scss';
import IconSvg from 'common/javascripts/components/IconSvg';

const TbankButton = ({
  className, onClick, lable, large, classNameButton, classNameInfo, classNameLogo,
}) => (
  <div
    className={
      cn(
        styles.TbankButton,
        { [className]: className },
      )
    }
  >
    <button
      type="button"
      className={cn(
        'button button_fullwidth',
        styles.TbankButton,
        { ['button_large']: large },
        { [classNameButton]: classNameButton },
      )}
      onClick={() => onClick()}
    >
      <div className={cn(styles.TbankButton__info, { [classNameInfo]: classNameInfo })}>{lable}</div>
      <IconSvg icon="t_id" className={cn(styles.TbankButton__logo, { [classNameLogo]: classNameLogo })} />
      {/* <IconSvg icon={SnowflakeIcon} className={styles.TbankButton__avatar} /> */}
    </button>
  </div>
);

TbankButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  lable: PropTypes.string,
  large: PropTypes.bool,
  classNameButton: PropTypes.string,
  classNameInfo: PropTypes.string,
  classNameLogo: PropTypes.string,
};

TbankButton.defaultProps = {
  className: '',
  lable: 'Войти с',
  large: true,
  classNameButton: '',
  classNameInfo: '',
  classNameLogo: '',
};

export default TbankButton;
