// copypasted default runtime logic with adoptation to turbolinks events.
import BrowserSprite from 'svg-baker-runtime/src/browser-sprite';

const spriteNodeId = '__SVG_SPRITE_NODE__';
const spriteGlobalVarName = '__SVG_SPRITE__';
const isSpriteExists = !!window[spriteGlobalVarName];

// eslint-disable-next-line import/no-mutable-exports
let sprite;

if (isSpriteExists) {
  sprite = window[spriteGlobalVarName];
} else {
  sprite = new BrowserSprite({ attrs: { id: spriteNodeId } });
  window[spriteGlobalVarName] = sprite;
}

const loadSprite = () => {
  /**
   * Check for page already contains sprite node
   * If found - attach to and reuse it's content
   * If not - render and mount the new sprite
   */
  const existing = document.getElementById(spriteNodeId);


  if (existing) {
    sprite.attach(existing);
  } else {
    sprite.mount('#svgSpriteContainer', true);
  }
};

// We need to clear state manually because 
// with turbolinks after visit new page isMountet still true.
const clearSpriteIsMountedState = () => {
  delete sprite.node
}

if (document.body) {
  loadSprite();
} else {
  document.addEventListener("turbolinks:request-start", () => clearSpriteIsMountedState())
  document.addEventListener("turbolinks:load", () => loadSprite())
}

export default sprite;