import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import CardVideos from '../../../../../components/CardVideos';
import CardAudios from '../../../../../components/CardAudios';
import CardImages from '../../../../../components/CardImages';
import StepHeader from '../StepHeader';
import TaskDescription from '../../../components/Description';
import TaskIdFavStatus from '../../../components/IdFavStatus';
import BoostersList from '../../../components/BoostersList';
import TaskCallWriteSnippet from '../CallWriteSnippet';
import AnswerText from '../AnswerText';
import AnswerCheckboxes from '../AnswerCheckboxes';
import AnswerRadioButtons from '../AnswerRadioButtons';
import AnswerSocial from '../AnswerSocial';
import AnswerOwnSocial from '../AnswerOwnSocial';
import AnswerAttachment from '../AnswerAttachment';
import AnswerQRCodeScan from '../AnswerQRCodeScan';
import AnswerQRCodeShow from '../AnswerQRCodeShow';
import AnswerGeo from '../AnswerGeo';
import AnswerInvite from '../AnswerInvite';
import HintsList from '../HintsList';
import AnswerTbankSubscription from '../AnswerTbankSubscription';

import { isTaskModerator } from '../../../../../helpers/players';
import { taskStepElementId } from '../../../../../helpers/tasks';

import styles from './StepRegular.module.scss';

const stepComponents = {
  text: AnswerText,
  checkboxes: AnswerCheckboxes,
  radioboxes: AnswerRadioButtons,
  social: AnswerSocial,
  own_social: AnswerOwnSocial,
  attach: AnswerAttachment,
  qrcode_scan: AnswerQRCodeScan,
  qrcode_show: AnswerQRCodeShow,
  geo: AnswerGeo,
  invite: AnswerInvite,
  qrcode_scan30: AnswerQRCodeScan,
  qrcode_scan60: AnswerQRCodeScan,
  qrcode_scan90: AnswerQRCodeScan,
  qrcode_scan3: AnswerQRCodeScan,
  qrcode_scan6: AnswerQRCodeScan,
  qrcode_scan9: AnswerQRCodeScan,
  tbank_subscribe: AnswerTbankSubscription,
};

function TaskStepRegular({
  taskStep,
  stepNumber,
  task,
  status,
  player,
  contact,
  token,
  onTaskAnswer,
  onTaskFetch,
}) {
  const isOneStepTask = task.steps_count === 1;
  const isCompleted = status === 'completed' || status === 'invite_completed';
  const isAvailable = status === 'available' || status === 'invite_pending' || status === 'fail_invite';
  const isPending = status === 'pending';
  const isHintsAvailable = taskStep.hints && taskStep.hints.length > 0;
  const isHintBoosterAvailable = taskStep.available_task_step_hints > taskStep.hints.length;

  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setIsCollapsed(!isAvailable);
  }, [isAvailable]);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const StepComponent = stepComponents[taskStep.answer_type];

  return (
    <>
      <Card
        noShadow
        className={
          cn(styles.StepRegular,
            styles[`StepRegular_${status}`],
            { [styles.StepRegular_collapsed]: isCollapsed })
        }
      >
        <StepHeader
          stepTitle={taskStep.title}
          stepId={taskStepElementId(taskStep.id)}
          stepNumber={stepNumber}
          isOneStepTask={isOneStepTask}
          isCompleted={isCompleted}
          isPending={isPending}
          toggleCollapsed={toggleCollapsed}
        />

        {!isCollapsed && (
          <>
            <TaskDescription description={taskStep.description} />

            <CardImages images={taskStep.images} header="Посмотри внимательно изображение" />
            <CardVideos videos={taskStep.videos} header="Посмотри внимательно видео" />
            <CardAudios audios={taskStep.audios} header="Послушай внимательно аудио" />

            {
              (isHintBoosterAvailable && !isCompleted) && (
                <BoostersList
                  taskStepId={taskStep.id}
                  boosters={taskStep.boosters}
                  onBoosterUse={onTaskFetch}
                />
              )
            }

            {isHintsAvailable && <HintsList items={taskStep.hints} />}

            {
              (!isCompleted && !isPending) && (
                <div className={styles.StepRegular__answer}>
                  <CardHeader className={styles.StepRegular__answerHeader}>
                    <h2>Чтобы завершить задание</h2>
                  </CardHeader>

                  <StepComponent
                    taskStep={taskStep}
                    token={token}
                    player={player}
                    onTaskAnswer={onTaskAnswer}
                  />
                </div>
              )
            }
            {isAvailable && <TaskIdFavStatus task={task} />}
          </>
        )}

        {isCompleted && (
          <TaskDescription description={taskStep.step_finish_description} />
        )}
      </Card>
      {isAvailable && <TaskCallWriteSnippet contact={contact} />}
    </>
  );
}

TaskStepRegular.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    answer_type: PropTypes.oneOf(Object.keys(stepComponents)),
    hints: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    boosters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    videos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    audios: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    available_task_step_hints: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    step_finish_description: PropTypes.string,
  }).isRequired,
  task: PropTypes.shape({
    steps_count: PropTypes.number,
  }).isRequired,
  status: PropTypes.string,
  stepNumber: PropTypes.number,
  token: PropTypes.string,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  onTaskAnswer: PropTypes.func,
  onTaskFetch: PropTypes.func,
};

TaskStepRegular.defaultProps = {
  status: '',
  stepNumber: 1,
  token: '',
  onTaskAnswer: () => {},
  onTaskFetch: () => {},
};

export default TaskStepRegular;
