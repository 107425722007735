import React from 'react';
import PropTypes from 'prop-types';

import BottomSlideUp from '../../../BottomSlideUp';

// import currentPlayerStore from '../../stores/currentPlayerStore';

// import AACardImage from '../../../images/card-aa.png';
import styles from './SlideUp.module.scss';

export default function TinkoffPromoSlideUp({ isBalance, show, onClose, setting }) {
  const handleClick = () => {
    if (!isBalance && setting.link_button_card) {
      window.open(setting.link_button_card, '_blank');
      onClose();
    }

    if (isBalance && setting.link_button_virtual_card) {
      window.open(setting.link_button_virtual_card, '_blank');
      onClose();
    }

    if ((!isBalance && !setting.link_button_card) || (isBalance && !setting.link_button_virtual_card)) onClose();
  };

  return (
    <BottomSlideUp show={show} onClose={onClose}>
      <div className={styles.SlideUp}>
        <div className={styles.SlideUp__text}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: isBalance ? setting.description_virtual_card : setting.description_card }} />
        </div>
      </div>
      <button
        className="button button_large button_fullwidth"
        onClick={handleClick}
        type="button"
      >
        {(!isBalance && !setting.text_button_card) && ('OK, супер')}
        {(!isBalance && setting.text_button_card) && setting.text_button_card}
        {(isBalance && !setting.text_button_virtual_card) && ('OK, супер')}
        {(isBalance && setting.text_button_virtual_card) && setting.text_button_virtual_card}
      </button>
    </BottomSlideUp>
  );
}

TinkoffPromoSlideUp.propTypes = {
  isBalance: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setting: PropTypes.shape({
    mg_url_card: PropTypes.string,
    title_card: PropTypes.string,
    text_card: PropTypes.string,
    description_card: PropTypes.string.isRequired,
    text_button_card: PropTypes.string.isRequired,
    link_button_card: PropTypes.string.isRequired,
    img_url_virtual_card: PropTypes.string.isRequired,
    title_virtual_card: PropTypes.string.isRequired,
    text_virtual_card: PropTypes.string.isRequired,
    description_virtual_card: PropTypes.string.isRequired,
    text_button_virtual_card: PropTypes.string.isRequired,
    link_button_virtual_card: PropTypes.string.isRequired,
  }).isRequired,
};

TinkoffPromoSlideUp.defaultProps = {
  isBalance: false,
};
