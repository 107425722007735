/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './MenuName.module.scss';
import PlayerSubscriptionSection from '../PlayerSubscriptionSection';

export default function TopbarMenuName({ name, fullName, player, team, linkUrl, linkEditPassword }) {
  const currentName = fullName?.split(' ')?.map((elem) => (
    <span key={`span_${elem}`}>{elem}</span>
  ));

  return (
    <div className={styles.Name}>
      <div className={styles.Name__nameAndSubscription}>
        <div className={styles.Name__wrap}>
          {!player && <span>{name}</span>}
          {player && currentName}
        </div>
      </div>

      {player && (
        <div className={styles.Name__profileAndPassword}>
          <div className={styles.Name__link}>
            <a href={linkUrl}>
              {!player?.individual_subscription_tbank && <IconSvg icon="edit-circle" className={styles.Name__editIcon} />}
              {player?.individual_subscription_tbank && (
                <div className={styles.Name__wrapSubscriptionTbank}>
                  <PlayerSubscriptionSection player={player} />
                </div>
              )}
              <span>Мой профиль</span>
            </a>
          </div>
          <div className={styles.Name__link}>
            <a href={linkEditPassword}>
              <IconSvg icon="password-edit" className={styles.Name__editIcon} />
              <span>Изменить пароль</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

TopbarMenuName.propTypes = {
  name: PropTypes.string,
  fullName: PropTypes.string,
  linkUrl: PropTypes.string,
  player: PropTypes.shape({
    individual_subscription_tbank: PropTypes.string,
  }),
  team: PropTypes.shape({
    id: PropTypes.number,
  }),
  linkEditPassword: PropTypes.string,
};

TopbarMenuName.defaultProps = {
  name: '',
  fullName: '',
  linkUrl: null,
  player: null,
  team: null,
  linkEditPassword: '',
};
